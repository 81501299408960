export function Footer() {
  return (
    <footer className="bg-slate-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10">
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            <a href="https://colombo.tech/" className="mr-1 text-indigo-600">
              Colombo Tech, LLC
            </a>
            &copy; 2017-{new Date().getFullYear()}. Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  )
}

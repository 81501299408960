import { Outlet } from '@remix-run/react'

import { Footer } from '~/components/public/Footer'
import { Header } from '~/components/public/Header'

export default function PublicLayout() {
  return (
    <>
      <Header />
      <main className="flex flex-1 flex-col">
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
